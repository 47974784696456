import { StacksTestnet, StacksMainnet } from "@stacks/network";
import { openContractCall } from "@stacks/connect";
import {
  uintCV,
  PostConditionMode,
  callReadOnlyFunction,
  cvToJSON,
  standardPrincipalCV,
} from "@stacks/transactions";

export const testnet = new StacksTestnet();
export const mainnet = new StacksMainnet();
// export const network = testnet;
export const network = mainnet;
// export const networkString = "testnet";
export const networkString = "mainnet";
export const isTestnet = network === testnet;
export const isMainnet = network === mainnet;
// export const basePath = "https://stacks-node-api.testnet.stacks.co";
export const basePath = "https://stacks-node-api.mainnet.stacks.co";
// export const contractName = "gbc-test5";
export const contractName = "groober-city";
// export const contractAddress = "ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7";
export const contractAddress = "SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA";
// export const artistAddress = "ST1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XZ54PKG7";
export const artistAddress = "SPJAPA3VKBC4CJBGG2B3N6GSW5AWPVFV3R5FRBM1";
export const tokenName = "groober-city";
const imageSrcBase = "https://groober-city.s3.us-east-2.amazonaws.com";
export const appName = "Groober City";
export const assetName = "GBC";
export const assetPrice = "30000000";
export const twitterHandle = "Groober_City";
export const homepageTitle = "Welcome to Groober City!";

export function getSrc(id) {
  return `${imageSrcBase}/images/${id}.jpg`;
}

export function transferNft(index, fromAddress, toAddress) {
  const options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "transfer",
    functionArgs: [
      uintCV(index),
      standardPrincipalCV(fromAddress),
      standardPrincipalCV(toAddress),
    ],
    network: network,
    appDetails: {
      name: contractName,
      icon: window.location.origin + "/logo.png",
    },
    onFinish: (data) => {
      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${data.txId}`;
      console.log("View transaction in explorer: ", explorerTransactionUrl);
    },
    postConditionMode: PostConditionMode.Allow,
  };

  openContractCall(options);
}

export function burn(id) {
  const options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "burn",
    functionArgs: [uintCV(id)],
    network: network,
    appDetails: {
      name: "STXPLATES",
      icon: window.location.origin + "/logo.png",
    },
    onFinish: (data) => {
      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${data.txId}`;
      console.log("View transaction in explorer: ", explorerTransactionUrl);
    },
    postConditionMode: PostConditionMode.Allow,
  };

  openContractCall(options);
}

export function getTokenOwner(id) {
  // see if plate is claimed before minting
  let options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "get-owner",
    functionArgs: [uintCV(id)],
    network: network,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => console.log(cvToJSON(res).value.value))
    .catch((err) => console.log(err));
}

export function getLastTokenId() {
  // see if plate is claimed before minting
  let options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "get-last-token-id",
    functionArgs: [],
    network: network,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => console.log(cvToJSON(res).value.value))
    .catch((err) => console.log(err));
}

export function getTokenUri(id) {
  // see if plate is claimed before minting
  let options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "get-token-uri",
    functionArgs: [uintCV(id)],
    network: network,
    senderAddress: contractAddress,
  };

  callReadOnlyFunction(options)
    .then((res) => console.log(cvToJSON(res).value.value))
    .catch((err) => console.log(err));
}

export function pause() {
  const options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "pause-mint",
    functionArgs: [],
    network: network,
    appDetails: {
      name: "Groober City",
      icon: window.location.origin + "/logo.png",
    },
    onFinish: (data) => {
      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${data.txId}`;
      console.log("View transaction in explorer: ", explorerTransactionUrl);
    },
    postConditionMode: PostConditionMode.Allow,
  };

  openContractCall(options);
}

export function unpause() {
  const options = {
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "unpause-mint",
    functionArgs: [],
    network: network,
    appDetails: {
      name: "Groober City",
      icon: window.location.origin + "/logo.png",
    },
    onFinish: (data) => {
      const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${data.txId}`;
      console.log("View transaction in explorer: ", explorerTransactionUrl);
    },
    postConditionMode: PostConditionMode.Allow,
  };

  openContractCall(options);
}