import React from "react";
import { Link } from "react-router-dom";
import { authenticate, userSession, getAddress } from "../../auth";

function Navbar() {
  function handleLogout() {
    userSession.signUserOut(window.location.reload());
  }

  const authLinks = (
    <>
      {userSession.isUserSignedIn() ? (
        <div>
          <img
            className="w-2 pb-0.5 mr-1.5 inline"
            src="https://stxplates.s3.us-east-2.amazonaws.com/connected.png"
            alt="STXPLATES"
          />
          <>
            <Link
              className="whitespace-nowrap text-base text-blue-500 hover:underline font-medium"
              to={"/profile/" + getAddress()}
            >
              {getAddress().substring(0, 4) +
                "..." +
                getAddress().substring(getAddress().length - 4)}
            </Link>
          </>
        </div>
      ) : null}
      <Link
        className="whitespace-nowrap text-base font-medium text-blue-500 hover:underline"
        to="#"
        onClick={() => handleLogout()}
      >
        Sign out
      </Link>
    </>
  );

  const guestLinks = (
    <>
      <button
        className="whitespace-nowrap text-base font-medium text-blue-500 hover:underline"
        onClick={authenticate}
      >
        Connect
      </button>
    </>
  );

  return (
    <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10 px-5">
      <div className="flex justify-start lg:w-0 lg:flex-1 space-x-10">
        <Link to="/" className="text-gray-900 font-semibold tracking-tight">
          GROOBER CITY
        </Link>
        {/* <Link
          className="whitespace-nowrap text-base font-medium text-blue-500 hover:underline"
          to="/mint"
        >
          Mint
        </Link> */}        
      </div>
      <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">
        {userSession.isUserSignedIn() ? authLinks : guestLinks}
      </div>
    </div>
  );
}

export default Navbar;
