import fetch from "cross-fetch";
import {
  Configuration,
  AccountsApi,
  SmartContractsApi,
} from "@stacks/blockchain-api-client";
import {
  uintCV,
  cvToHex,
  hexToCV,
  cvToJSON,
} from "@stacks/transactions";
import { contractAddress, contractName, basePath } from "./contract";

export async function getTransactions(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const txs = await accountsApi.getAccountTransactions({
    principal: address,
  });

  console.log(txs);
}

export async function getNfts(address, limit, offset) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const nfts = await accountsApi.getAccountNft({
    principal: address,
    limit: limit,
    offset: offset,
  });

  return nfts;
}

export async function getBalance(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const balance = await accountsApi.getAccountBalance({
    principal: address,
  });

  console.log(balance);
}

export async function getAssets(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const assets = await accountsApi.getAccountAssets({
    principal: address,
  });

  return assets.results;
}

export async function callGetTokenUri(
  contractAddress,
  contractName,
  functionName,
  tokenId
) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const contractApi = new SmartContractsApi(apiConfig);

  const id = uintCV(tokenId);

  const uri = await contractApi.callReadOnlyFunction({
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: functionName,
    readOnlyFunctionArgs: {
      sender: contractAddress,
      arguments: [cvToHex(id)],
    },
  });

  return cvToJSON(hexToCV(uri.result)).value.value;
  // console.log(cvToJSON(hexToCV(uri.result)).value.value.value);
}

export async function getIndex(tokenId) {
  console.log(tokenId);

  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);

  const id = uintCV(tokenId);

  const uri = await api.callReadOnlyFunction({
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "get-index-by-token",
    readOnlyFunctionArgs: {
      sender: contractAddress,
      arguments: [id],
    },
  });

  return cvToJSON(hexToCV(uri.result)).value.value;
}

export async function getLastTokenId() {

  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);

  const uri = await api.callReadOnlyFunction({
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: "get-last-token-id",
    readOnlyFunctionArgs: {
      sender: contractAddress,
      arguments: [],
    },
  });

  return cvToJSON(hexToCV(uri.result)).value.value;
}
