import "./App.css";
import Navbar from "./components/layout/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import Pause from "./components/pages/Pause";
import Profile from "./components/pages/Profile";
import AdminRoute from "./components/common/AdminRoute";

function App() {
  return (
    <Router>
      <div className="flex flex-col h-screen mx-auto px-4 sm:px-6">
        <div>
          <Navbar />
        </div>
        <div className="mb-auto">
          <Route exact path="/" component={Home} />
          <Switch>
            <Route path="/profile/:address" component={Profile} />
          </Switch>
          <Switch>
            <AdminRoute exact path="/pause" component={Pause} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
