import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getNfts } from "../../utils/api";
import { contractAddress, contractName, getSrc, tokenName } from "../../utils/contract";
import Spinner from "../common/Spinner";

function Profile() {
  const [tokens, setTokens] = useState(null);

  const { address } = useParams();

  useEffect(() => {
    async function getTokens() {
      const limit = 50;
      let offset = 0;
      const total = (await getNfts(address, limit, offset)).total;
      const loops = Math.ceil(total / limit);
      let tokenIds = [];
      for (let i = 0; i < loops; i++) {
        // call get nfts with loop offset
        offset = i * limit;
        const events = (await getNfts(address, limit, offset)).nft_events;
        for (let j = 0; j < events.length; j++) {
          const event = events[j];
          console.log(event);
          if (
            event.asset_identifier ===
            `${contractAddress}.${contractName}::${tokenName}`
          ) {
            tokenIds.push(event.value.repr.substring(1));
          }
        }
      }

      setTokens(tokenIds);
    }

    getTokens();
  }, [address]);

  let pieces = [];
  if (tokens !== null && tokens.length > 0) {
    for (let i = 0; i < tokens.length; i++) {
      const id = tokens[i];
      const src = getSrc(id);
      let tokenImage = (
        <img key={id} className="w-48" src={src} alt={"#" + id} />
      );
      pieces.push(tokenImage);
    }
  } else if (tokens !== null) {
    pieces = <p>Looks like this address doesn't have any pieces yet :(</p>;
  }

  return (
    <div className="max-w-6xl mx-auto py-16 sm:px-6 lg:px-8 text-center">
      <h2 className="text-base text-gray-900 text-xl font-bold tracking-wide uppercase text-center pb-8">
        {address.substring(0, 4) +
          "..." +
          address.substring(address.length - 4)}
      </h2>
      <div className="flex justify-center my-16 px-24">
        {tokens !== null ? (
          <div className="flex flex-wrap gap-12 justify-center items-center">
            {pieces}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

export default Profile;
