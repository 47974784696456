import { AppConfig, UserSession, showConnect } from "@stacks/connect";
import { appName, isTestnet } from "./utils/contract";

const appConfig = new AppConfig(["store_write", "publish_data"]);
export const userSession = new UserSession({ appConfig });

export function authenticate() {
  showConnect({
    appDetails: {
      name: appName,
      icon: window.location.origin + "/logo.png",
    },
    redirectTo: "/",
    onFinish: () => {
      window.location.reload();
    },
    userSession: userSession,
  });
}

export function getUserData() {
  return userSession.loadUserData();
}

export function displayName() {
  const userData = getUserData();
  return userData.username || userData.identityAddress;
}

export function getMainnetAddress() {
  const userData = getUserData();
  return userData.profile.stxAddress.mainnet;
}

export function getTestnetAddress() {
  const userData = getUserData();
  return userData.profile.stxAddress.testnet;
}

export function getAddress() {
  const userData = getUserData();
  if (isTestnet) {
    return userData.profile.stxAddress.testnet;
  } else {
    return userData.profile.stxAddress.mainnet;
  }
}
