import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
import {
  userSession,
  getAddress,
} from "../../auth";
import { artistAddress, contractAddress } from "../../utils/contract";

function AdminRoute(props) {
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // return (
  //   <>{isAuthenticated ? <Route {...props} /> : <Redirect to="/login" />}</>
  // );

  return (
    <>
      {userSession.isUserSignedIn() &&
      (getAddress() === contractAddress ||
        getAddress() === artistAddress) ? (
        <Route {...props} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default AdminRoute;
