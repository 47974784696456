import React, { useEffect, useState } from "react";
import { userSession, authenticate, getAddress } from "../../auth";
import {
  contractName,
  contractAddress,
  network,
  // getSrc,
  appName,
  assetName,
  assetPrice,
  twitterHandle,
  homepageTitle,
  networkString,
} from "../../utils/contract";
import {
  stringAsciiCV,
  // uintCV,
  PostConditionMode,
  FungibleConditionCode,
  NonFungibleConditionCode,
  makeStandardSTXPostCondition,
  makeStandardNonFungiblePostCondition,
  callReadOnlyFunction,
  cvToJSON,
  createAssetInfo,
} from "@stacks/transactions";
import { openContractCall } from "@stacks/connect";
import gif from "../../../src/grooberCity.gif";
// import { getLastTokenId } from "../../utils/api";

const BN = require("bn.js");

function Home() {
  // const [src, setSrc] = useState(null);
  const [minted, setMinted] = useState("...");
  const [transaction, setTransaction] = useState(null);
  const [paused, setPaused] = useState(false);

  const twitterPath = "https://twitter.com/" + twitterHandle;

  window.onload = function name() {
    if (userSession.isSignInPending()) {
      console.log("pending");
      userSession.handlePendingSignIn().then((userData) => {
        console.log(userData);
      });
    } else if (userSession.isUserSignedIn()) {
      console.log("user already signed in");
    }
  };

  useEffect(() => {
    // function getRandomInt(min, max) {
    //   min = Math.ceil(min);
    //   max = Math.floor(max);
    //   return Math.floor(Math.random() * (max - min + 1)) + min;
    // }

    // const id = getRandomInt(1, 400);
    // setSrc(getSrc(id));

    async function getLastId() {
      const options = {
        contractAddress: contractAddress,
        contractName: contractName,
        functionName: "get-last-token-id",
        functionArgs: [],
        network: network,
        senderAddress: contractAddress,
      };

      const res = await callReadOnlyFunction(options);
      if (cvToJSON(res).value.value !== null) {
        const lastTokenId = cvToJSON(res).value.value;
        setMinted(lastTokenId);
      }
    }

    async function getPaused() {
      const options = {
        contractAddress: contractAddress,
        contractName: contractName,
        functionName: "get-is-paused",
        functionArgs: [],
        network: network,
        senderAddress: contractAddress,
      };

      const res = await callReadOnlyFunction(options);
      if (cvToJSON(res).value.value !== null) {
        const isPaused = cvToJSON(res).value.value;
        setPaused(isPaused);
      }
    }

    // get number of minted from blockchain api
    getLastId()
      .then()
      .catch((err) => console.log("Error: " + err));

    getPaused()
      .then()
      .catch((err) => console.log("Error: " + err));
  }, []);

  async function handleMint() {
    if (!userSession.isUserSignedIn()) {
      authenticate();
      return;
    }

    handleAlertClick();

    const options = {
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: "claim",
      functionArgs: [],
      network: network,
      appDetails: {
        name: appName,
        icon: window.location.origin + "/logo.png",
      },
      onFinish: (data) => {
        const explorerTransactionUrl = `https://explorer.stacks.co/txid/0x${data.txId}?chain=${networkString}`;
        console.log("View transaction in explorer: ", explorerTransactionUrl);
        setTransaction(explorerTransactionUrl);
      },
      postConditionMode: PostConditionMode.Deny,
      postConditions: [
        makeStandardSTXPostCondition(
          getAddress(),
          FungibleConditionCode.Equal,
          new BN(assetPrice)
        ),
        makeStandardNonFungiblePostCondition(
          getAddress(),
          NonFungibleConditionCode.Owns,
          createAssetInfo(contractAddress, contractName, assetName),
          stringAsciiCV(assetName)
        ),
      ],
    };

    openContractCall(options);
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async function handleAlertClick() {
    await timeout(1000);
    setTransaction(null);
  }

  return (
    <div>
      <main>
        <div className="max-w-4xl mx-auto sm:px-6 lg:px-8 my-16">
          <img className="mx-auto my-16" src="/banner.jpg" alt="Groober City" />
          <div className="text-3xl font-bold text-center mb-8 mt-24">
            <p>{homepageTitle}</p>
          </div>
          <div className="font-medium text-lg mb-24">
            GROOBER CITY is a collection of 400 Unique Characters from the
            digital city of the same name that was contaminated by toxic nuclear
            waste turning its citizens (GROOBIZENS) into some cool looking
            monsters. Some Groobizens gained supernatural powers from their
            mutation, turning into Rare Super Groobers with unique super powers.
          </div>
          <img className="mx-auto w-72 my-16" src={gif} alt="Groober City" />
          {paused ? (
            <div className="flex mb-12">
              <button
                disabled
                className="mx-auto bg-gray-500 opacity-50 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleMint}
              >
                Minting paused!
              </button>
            </div>
          ) : (
            <div className="flex mb-12">
              <button
                className="mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleMint}
              >
                Mint a Groobizen!
              </button>
            </div>
          )}

          <p className="text-center text-gray-900 text-sm font-semibold italic mb-1">
            {minted}/400 minted
          </p>
          <p className="text-center text-gray-900 text-sm font-semibold italic mb-1">
            1 groober = 30 STX
          </p>
          <p className="text-center text-gray-900 text-sm font-semibold italic mb-24">
            collection consists of 400 unique groobers
          </p>
          <div className="text-center mb-1">
            <a
              href={twitterPath}
              className="whitespace-nowrap text-base font-semibold text-blue-500 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              @{twitterHandle}
            </a>
          </div>
          {/* <div className="text-center mb-2">
            <a
              href="https://t.co/RClkHlWFkU?amp=1"
              className="whitespace-nowrap text-base font-semibold text-blue-500 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
          </div> */}
        </div>
      </main>
      {transaction !== null ? (
        <div className="alert-toast fixed bottom-0 right-0 m-8 w-5/6 md:w-full max-w-sm">
          <input type="checkbox" className="hidden" id="footertoast" />

          <label
            className="close cursor-pointer flex items-start justify-between w-full p-4 h-24 rounded shadow-lg text-blue-500 border-4 border-blue-500"
            title="close"
            htmlFor="footertoast"
            onClick={handleAlertClick}
          >
            <a
              href={transaction}
              className="whitespace-nowrap text-base font-semibold text-blue-500 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Congrats! View your transaction.
            </a>
            <svg
              className="fill-current text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </label>
        </div>
      ) : null}
    </div>
  );
}

export default Home;
