import React from "react";
import {
  pause,
  unpause,
} from "../../utils/contract";

function Admin() {
  return (
    <div className="max-w-3xl mx-auto py-32 sm:px-6 lg:px-8 text-center">
      <div className="text-center">
        <div className="mb-2">
          <button
            className="text-blue-500 hover:underline"
            onClick={() => pause()}
          >
            pause minting
          </button>
        </div>
        <div className="mb-2">
          <button
            className="text-blue-500 hover:underline"
            onClick={() => unpause()}
          >
            unpause minting
          </button>
        </div>
      </div>
    </div>
  );
}

export default Admin;
